import React, { useState, useEffect } from "react";
import Web3 from "web3";
import "./styles.css";
import ReCAPTCHA from "react-google-recaptcha";

const privateKey = process.env.REACT_APP_PRIVATE_KEY;
const amountToSend = Web3.utils.toWei(process.env.REACT_APP_AMOUNT, "ether");
const web3 = new Web3("https://faucet.mstblockchain.com/api");

const account = web3.eth.accounts.privateKeyToAccount(privateKey);
web3.eth.accounts.wallet.add(account);

function App() {
  const [inputValue, setInputValue] = useState("");
  const [lastTimestamp, setlastTimestamp] = useState("");
  const [loading, setLoading] = useState(false);
  const [waitTime, setWaitTime] = useState(0);

  useEffect(() => {
    const storedTimestamps = JSON.parse(
      localStorage.getItem("transactionTimestamps") || "{}"
    );
    const lastTxForAddress = storedTimestamps[inputValue];
    if (lastTxForAddress) {
      setlastTimestamp(parseInt(lastTxForAddress));
    }
  }, [inputValue]);

  useEffect(() => {
    if (lastTimestamp) {
      const currentTime = Date.now();
      const timeDiff = currentTime - lastTimestamp;
      const hoursDiff = timeDiff / (1000 * 60 * 60);

      if (hoursDiff < 24) {
        setWaitTime(24 - Math.floor(hoursDiff));
      } else {
        setWaitTime(0);
      }
    }
  }, [lastTimestamp]);

  function onChange(value) {
    console.log("Captcha value:", value);
  }

  const handleClick = async () => {
    if (waitTime > 0) {
      alert(
        `Please try again after ${waitTime} hour${waitTime > 1 ? "s" : ""} for your next faucet request.`
      );
      return;
    }

    if (!web3.utils.isAddress(inputValue)) {
      alert("Please enter a valid Ethereum address");
      return;
    }

    setLoading(true);
    try {
      const gasPrice = await web3.eth.getGasPrice();

      const gasEstimate = await web3.eth.estimateGas({
        from: account.address,
        to: inputValue,
        value: amountToSend,
      });

      const tx = {
        from: account.address,
        to: inputValue,
        value: amountToSend,
        gas: gasEstimate,
        gasPrice: gasPrice,
      };

      const receipt = await web3.eth.sendTransaction(tx);
      console.log(`Transaction hash: ${receipt.transactionHash}`);
      alert(`Transaction successful: ${receipt.transactionHash}`);

      const storedTimestamps = JSON.parse(
        localStorage.getItem("transactionTimestamps") || "{}"
      );
      storedTimestamps[inputValue] = Date.now();
      localStorage.setItem("transactionTimestamps", JSON.stringify(storedTimestamps));

      setlastTimestamp(Date.now());
      setWaitTime(24);
    } catch (error) {
      console.error("Transaction failed", error);
      alert("Transaction failed: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <img src="/images/blockscout_logo.svg" className="logo" alt="logo" />
      <div className="modal-container">
        <h1>MST TESTNET FAUCET</h1>
        <input
          type="text"
          value={inputValue}
          placeholder="Recipient address"
          onChange={(e) => setInputValue(e.target.value)}
          disabled={loading}
        />

        <ReCAPTCHA
          sitekey="6LcBcV4qAAAAAFioZOaRbq7DmVR1hi7OazxE2h-S"
          onChange={onChange}
          className="recaptcha"
        />

        <button onClick={handleClick} disabled={loading}>
          {loading ? "Processing..." : "Get Test MST"}
        </button>

        <img src="/images/rec-top.png" className="rec-top" />
        <img src="/images/rec-bottom.png" className="rec-bottom" />
      </div>
    </div>
  );
}

export default App;
